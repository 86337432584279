<template>
  <b-form class="mt-3" id="resDetForm" v-if="item" @submit.stop.prevent>
    <DetailsContent :item="item" />
    <b-form-row class="justify-content-end">
      <b-col cols="*">
        <ButtonSet type="b-form-row" :useCols="true" suffix="Det" :id="0" @close="onClose"
            :item="$store.state.oResDetails" :saveResult="true" class="justify-content-end"
            :showCancel="true" :showReject="true" :showDelete="true" :showAccept="true" :showConfirm="true" :showDispute="true" :showResolve="true" :showProtest="true"
            :beforeShow="true" beforeVariant="primary" beforeSuffix="Back" @beforeClick="onBack"
            :afterShow="!isBlock&&(isUser||(!isUser&&isPayable))" afterVariant="primary" afterSuffix="Qrc" @afterClick="onShow">
          <template #before>
            <Icon type="b" /> {{ $t('btn.b') }}
          </template>
          <template v-if="isUser" #after>
            <Icon type="qr" /> {{ $t('res.bqr') }}
          </template>
          <template v-else-if="isPayable" #after>
            <Icon type="tra" /> {{ $t('res.bpy') }}
          </template>
        </ButtonSet>
      </b-col>
    </b-form-row>
    <ModalQrShow v-if="isUser && showModal" @hook:mounted="onLoad('qrShowWindow')" @submit="onClose" @reset="onClose" />
    <ModalTransCreate v-if="!isUser && showModal && isPayable" @submit="onClose" @reset="onClose" id="resTraCre"
        :senderUuid="uuid4" :reservationUuid="item.uuid4" />
  </b-form>
</template>

<script>
import Icon from '@/components/Icon';
import DetailsContent from '@/components/reservations/DetailsContent';
import ButtonSet from '@/components/reservations/ButtonSet';

export default {
  data() {
    return {
      showModal: false,
    };
  },
  computed: {
    isUser() {
      return this.$store.state.oLogin.isUser;
    },
    item() {
      return this.$store.state.oResDetails;
    },
    uuid4() {
      return this.item.userUuid4;
    },
    isBlock() {
      return this.item.userUuid4 == this.item.customerUuid4;
    },
    isPayable() {
      return this.item.state === 'SHOWED';
    },
  },
  methods: {
    onBack() {
      this.$router.go(-1); // return from where we came from
    },
    onShow() {
      this.showModal = true;
    },
    onLoad(modal) {
      // ModalTrans HTML from above (since switched to auto-show when v-if becomes true):  id="traCreateWindow" @hook:mounted="onLoad('traCreateWindow')"
      this.$bvModal.show(modal); // is now shown automatically when v-if becomes true
    },
    onClose() {
      this.showModal = false;
      // reload data
      this.$store.commit('componentUpdate', 'traTab'); // better communication via store, instead of chain of props to sub-sub-comp
    },
  },
  components: {
    DetailsContent,
    ButtonSet,
    ModalQrShow: () => import('@/components/shared/ModalQrShow'),
    ModalTransCreate: () => import('@/components/transactions/ModalTransCreate'),
    Icon,
  },
}
</script>
