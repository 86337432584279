<template>
  <div v-if="item!=null">
    <b-form-row class="text-center mb-3">
      <b-col v-if="isUser" cols="12">
        <i18n path="res.tdu" tag="h5">
          <template #name>
            <b>{{ item.customerName }}</b>
          </template>
          <template #pill>
            <b-badge pill :variant="getBadgeV">
              {{ getBadgeT }}
            </b-badge>
          </template>
        </i18n>
      </b-col>
      <b-col v-else cols="12">
        <i18n path="res.tdc" tag="h5">
          <template #phone>
            <b v-if="isBlock">{{ $t('res.btd') }}</b>
            <b-link v-else-if="item.userPhone" :href="`tel:`+item.userPhone.replace(/\s/g,'')">
              <b v-text="getContact(item.userName, item.userPhone)" />
            </b-link>
            <b v-else>{{ getContact(item.userName, item.userPhone) }}</b>
          </template>
          <template #pill>
            <b-badge pill :variant="getBadgeV">
              {{ getBadgeT }}
            </b-badge>
          </template>
        </i18n>
      </b-col>
    </b-form-row>
    <b-form-row class="flex-column flex-sm-row">
      <b-col cols="*" sm="6">
        <b-form-group :label="$t('res.fda')" label-for="resDetDate" label-class="mr-1" label-cols="4">
          <b-input-group>
            <template #prepend>
              <b-input-group-text>
                <b-icon-calendar />
              </b-input-group-text>
            </template>
            <b-input type="text" readonly id="resDetDate" :value="getDate" />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="*" sm="6">
        <b-form-group :label="$t('res.fti')" label-for="resDetTime" label-class="mr-1 pl-sm-4" label-cols="4">
          <b-input-group>
            <template #prepend>
              <b-input-group-text>
                <b-icon-clock />
              </b-input-group-text>
            </template>
            <b-input type="text" readonly id="resDetTime" :value="getTime" />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row class="flex-column flex-sm-row">
      <b-col cols="*" sm="6">
        <b-form-group :label="$t('res.ffo')" label-for="resDetSize" label-class="mr-1" label-cols="4">
          <b-input-group>
            <template #prepend>
              <b-input-group-text>
                <b-icon-people />
              </b-input-group-text>
            </template>
            <b-input type="text" readonly id="resDetSize" :value="item.seats" />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="*" sm="6" v-if="!isUser">
        <b-form-group :label="$t('res.fna')" label-for="resDetTab" label-class="mr-1 pl-sm-4" label-cols="4">
          <b-input-group>
            <template #prepend>
              <b-input-group-text>
                <b-icon-collection />
              </b-input-group-text>
            </template>
            <b-input type="text" readonly id="resDetTab" :value="getName" :class="item.tableName==null?`font-italic`:``" />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row v-if="item.userMessage" class="flex-column flex-sm-row">
      <b-col cols="auto" sm="12">
        <b-form-group :label="$t('res.euml')" label-for="resDetMsg" label-class="text-left mr-1" label-cols="4" label-cols-sm="2">
          <b-textarea id="resDetMsg" readonly rows="3" :value="item.userMessage" />
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row v-if="item.customerMessage" class="flex-column flex-sm-row">
      <b-col cols="auto" sm="12">
        <b-form-group :label="$t('res.erml')" label-for="resDetCust" label-class="text-left mr-1" label-cols="4" label-cols-sm="2">
          <b-textarea id="resDetCust" readonly rows="3" :value="item.customerMessage" />
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row v-if="!isUser && item.customerNote" class="flex-column flex-sm-row">
      <b-col cols="auto" sm="12">
        <b-form-group :label="$t('res.einl')" label-for="resDetNote" label-class="text-left mr-1" label-cols="4" label-cols-sm="2">
          <b-textarea id="resDetNote" readonly rows="3" :value="item.customerNote" />
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row class="flex-column flex-sm-row" v-if="!isBlock">
      <b-col cols="auto" sm="12">
        <b-form-group :label="$t('nav.tra.t')" label-for="resDetTra" label-class="text-left pr-0 mr-1" label-cols="12" label-cols-sm="2">
          <TransTable suffix="resDetTra" showShow
              :resUuid="item.uuid4" useResUuid :query="{}" :noHitText="$t('res.tno')" />
        </b-form-group>
      </b-col>
    </b-form-row>
  </div>
</template>

<script>
import { BIconPeople, BIconClock, BIconCalendar, BIconCollection } from 'bootstrap-vue';
import { utcToZonedTime } from 'date-fns-tz';
import TransTable from '@/components/transactions/TransTable';

export default {
  computed: {
    getDate() {
      return this.$d(utcToZonedTime(this.item.startAt+'Z', this.$store.state.settings.timezone),'ds');
    },
    getTime() {
      return this.$t('res.ftd', [this.$d(utcToZonedTime(this.item.startAt+'Z', this.$store.state.settings.timezone),'t'), this.getDuration]);
    },
    getDuration() {
      return this.$t('res.dur', [this.$n(this.item.duration, 'd')]);
    },
    getName() {
      if (this.item.tableName == null) return this.$t('res.empty');
      return this.item.tableName;
    },
    getBadgeV() {
      if (['CREATED'].includes(this.item.state)) return 'secondary';
      else if (['LOCKED','ACCEPTED','SHOWED'].includes(this.item.state)) return 'primary';
      else if (['DISPUTED','ESCALATED','NOSHOWED'].includes(this.item.state)) return 'danger';
      else return 'warning';
    },
    getBadgeT() {
      if (['CREATED'].includes(this.item.state)) return this.$t('res.s.'+'new');
      else if (['LOCKED','ACCEPTED'].includes(this.item.state)) return this.$t('res.s.'+'accepted');
      else return this.$t('res.s.'+this.item.state.toLowerCase());
    },
    isLoggedIn() {
      return this.$store.state.oLogin.isLoggedIn;
    },
    isUser() {
      return this.$store.state.oLogin.isUser;
    },
    isBlock() {
      return this.item.userUuid4 == this.item.customerUuid4;
    },
  },
  methods: {
    getContact(u, v) {
      if (!v) return u;
      if (!u) return v;
      return u + ' ' + v;
    },
  },
  props: {
    item: {
      type: Object,
      required: true,
    }
  },
  components: {
    BIconPeople,
    BIconClock,
    BIconCalendar,
    BIconCollection,
    TransTable,
  },
}
</script>

<style scoped>
  h5>.badge {
    vertical-align:middle;
    margin-top: -0.25em;
  }
</style>
